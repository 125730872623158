/* eslint-disable react/no-array-index-key */
import React from 'react'
import styled from 'styled-components'
import { info } from '../../utils/constants/info'
import TranslatorDescription from './TranslatorContent/TranslatorDescription'

const Footer = () => {
  return (
    <StyledFooter>
      <StyledNav>
        <Block>
          <StyledTitle>
            <TranslatorDescription variant='footer-info' />
          </StyledTitle>
          <StyledText href='/about-of-city-hall'>
            <TranslatorDescription variant='footer-cityHall' />
          </StyledText>
          <StyledText href='https://www.gov.kg/ky' target='_blank'>
            <TranslatorDescription variant='footer-republic' />
          </StyledText>
        </Block>
        <Block>
          <StyledTitle>
            <TranslatorDescription variant='footer-contacts' />
          </StyledTitle>
          {info.contacts.numbers.map((element, index) => (
            <StyledText key={index}>{element}</StyledText>
          ))}
        </Block>
        <Block>
          <StyledTitle>
            <TranslatorDescription variant='footer-job' />
          </StyledTitle>
          <StyledText>Требуется программист, секретарша</StyledText>
        </Block>
        <Block>
          <StyledTitle>
            <TranslatorDescription variant='footer-network' />
          </StyledTitle>
          <StyledSocial href='https://wa.me/+996771414446' target='_blank'>
            <Icon
              src='https://uxwing.com/wp-content/themes/uxwing/download/brands-and-social-media/whatsapp-white-icon.png'
              alt='none'
            />
            <p>{info.socialNetworks.telegram}</p>
          </StyledSocial>
          <StyledSocial href='mailto:kochkorata.msu@mail.ru'>
            <Icon
              src='https://www.pngkey.com/png/full/826-8263532_email-icon-email-icon-png-blue.png'
              alt='none'
            />
            <p>{info.socialNetworks.email}</p>
          </StyledSocial>
        </Block>
      </StyledNav>
      <Rights>
        <TranslatorDescription variant='footer-security' />
      </Rights>
    </StyledFooter>
  )
}

export default Footer

const StyledFooter = styled.footer`
  background: #242424;
  padding: 30px 200px 20px 100px;
  color: #fff;
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 200;
  line-height: normal;
  @media (max-width: 1025px) {
    padding-left: 50px;
    padding-right: 50px;
  }
  @media (max-width: 913px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`
const StyledNav = styled.nav`
  display: flex;
  justify-content: space-between;
  @media (max-width: 600px) {
    flex-direction: column;
  }
`
const Block = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  @media (max-width: 600px) {
    gap: 10px;
    margin-bottom: 25px;
  }
`
const StyledTitle = styled.h3`
  color: #fff;
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 5px;
  @media (max-width: 913px) {
    font-size: 18px;
  }
`

const StyledText = styled.a`
  color: #fff;
  font-family: Inter, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 200;
  line-height: normal;
  text-decoration: none;
  @media (max-width: 913px) {
    font-size: 12px;
  }
  transition: 0.2s;
  &:hover {
    color: #cecece;
  }
`
const StyledSocial = styled.a`
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  color: #fff;
  font-family: Inter, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 200;
  line-height: normal;
  margin-bottom: 4px;
  text-decoration: none;
  transition: 0.2s;
  &:hover {
    color: #cecece;
  }
`
const Icon = styled.img`
  pointer-events: none;
  width: 25px;
`
const Rights = styled.p`
  user-select: none;
  font-size: 15px;
`
