export const info = {
  contacts: {
    numbers: ['+996 771 414 446'],
  },
  socialNetworks: {
    // whatsapp: 'WhatsApp',
    // telegram: 'Telegram',
    email: 'kochkorata.msu@mail.ru ',
  },
}
